<template>
  <section class="forgot-password">
    <div v-if="isResetEmailSended" class="forgot-password-container">
      <h1 class="forgot-password-title">
        Check your email
      </h1>

      <h2 class="forgot-password-subtitle">
        We have sent a password recover instructions to your email
      </h2>
    </div>
    <div v-else class="forgot-password-container">
      <h1 class="forgot-password-title">
        Forgot password
      </h1>

      <h2 class="forgot-password-subtitle">
        We will send you instructions on how to reset your password by email
      </h2>
      <BRInput
        alt
        label="Email"
        placeholder="Enter your email"
        :value.sync="email"
        @pressEnter="changePassword"
      />
      <div>
        <span v-show="error" class="error-message">{{ error }}</span>
      </div>
      <div>
        <BRButton
          class="forgot-password-button"
          dark
          :loading="loading"
          @click="changePassword"
        >
          Send instructions
        </BRButton>
      </div>
    </div>
  </section>
</template>

<script>
import Firebase from 'firebase/app'
export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      error: '',
      loading: false,
      isResetEmailSended: false
    }
  },
  methods: {
    async changePassword() {
      this.loading = true
      if (!this.email) {
        this.loading = false
        this.error = 'Field is required'
        return
      }

      try {
        await Firebase
          .auth()
          .sendPasswordResetEmail(this.email)
        this.error = ''
        this.isResetEmailSended = true
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.forgot-password {
  padding: 136px 0;

  @include breakpoint-reverse(small) {
    padding: 0;
  }
}

.forgot-password-container {
  @include content;

  margin: 0 auto;
  padding: 28px 60px;
  max-width: 460px;

  @include breakpoint-reverse(small) {
    padding: 28px;
  }
}

.forgot-password-title {
  font-size: var(--font-h1);
  text-align: center;
}

.forgot-password-subtitle {
  margin-top: 5px;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-base);
  text-align: center;
}

.forgot-password-button {
  display: block;
  margin: 15px auto;
  width: 240px;

  @include breakpoint-reverse(small) {
    width: 100%;
  }
}

.input {
  margin-bottom: 16px;
}

.error-message {
  color: var(--error-color);
}
</style>
